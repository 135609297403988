






































import { defineComponent } from "@vue/composition-api";
import { Cell, CellGroup, Dialog, Toast } from "vant";
import "weui";
import weui from "weui.js";
import axios from "@/helpers/axios";

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  },
  data() {
    return {
      startTime: "",
      endTime: "",
      email: "",
      optionList: [],
      checked: []
    };
  },
  created() {
    this.email = localStorage.email || "";
    this.getBillExportInfo();
  },
  methods: {
    async getBillExportInfo() {
      const res = await axios
        .post("/api/manage/account/getBillExportInfo")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      this.optionList = res.optionList;
    },
    async billExport() {
      if (!/.+@.+/.test(this.email)) {
        Toast.fail("请正确的填写邮箱");
        return;
      }
      if (!this.startTime || !this.endTime) {
        Toast.fail("请选择时间");
        return;
      }
      if (this.checked.length <= 0) {
        Toast.fail("请选择需要导出的账单");
        return;
      }
      localStorage.email = this.email;
      const confirm = await Dialog.confirm({
        message: "请核对邮箱地址(" + this.email + ")，是否开始导出账单"
      })
        .then(() => true)
        .catch(() => false);
      if (!confirm) return;
      const res = await axios
        .post("/api/manage/account/billExport", {
          startTime: this.startTime,
          endTime: this.endTime,
          email: this.email,
          optionList: this.checked
        })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      Dialog.confirm({
        message: "导出完成, 请查收邮件",
        showCancelButton: false
      });
    },
    pickTime(target: string) {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();

      weui.datePicker({
        start: 2017,
        defaultValue: [year, month, day],
        onConfirm: (result: { value: number }[]) => {
          const y = result[0].value;
          const m = result[1].value;
          const d = result[2].value;
          const date = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
          if (target === "startTime") {
            this.startTime = date;
          } else if (target === "endTime") {
            this.endTime = date;
          }
        },
        id: "datePicker"
      });
    }
  }
});
